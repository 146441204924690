@import url(../../index.css);
header {
  display: flex;
  gap: 20px;
  padding: 0rem;
  width: 100%;
  margin: 0px;
  z-index: 1000000000000000000;
  height: 5rem;
  align-items: center;
  border: none;
  background-color: transparent;
}

#header {
  position: fixed;
}

.header a {
  padding-left: 0.3rem;
  text-decoration: none;
  flex: 2;
}

.logo-container {
  font-family: "A Goblin Appears!";
  font-size: 0.3rem;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 4px;
  padding-left: 0.5rem;
}

.logo-container .personal-logo {
  max-height: 20%;
  max-width: 25%;
  object-fit: contain;
  margin-right: 1rem;
}

.buttons {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.buttons input {
  height: 1.2rem;
  width: 1.2rem;
  border: 2px solid;
  border-radius: 0.5rem;
  padding: 0.5rem;
}

.menu {
  text-align: center;
  border: 2px solid rgb(95, 95, 95);
  position: fixed;
  top: 5.5%;
  left: 53%;
  right: 0%;
  border-radius: 0.5rem;
  width: 10rem;
  padding: 20px;
  padding-top: 0.4rem;
  transform: translateX(600%);
  transition: transform 0.2s;
  z-index: 10000000000000000;
}

.show {
  transform: translateX(0%);
}

.menu li {
  text-align: center;
  list-style: none;
  margin-top: 1rem;
  padding-top: 0.4rem;
  text-decoration-line: underline;
  text-decoration-thickness: 2px;
}

.menu a {
  font-size: 0.9rem;
  font-weight: 700;
  text-decoration: none;
  margin-top: 1rem;
}

.colorBtn {
  transform: translateY(0%);
  transition: 0.2s;
}

.colorBtnTransition {
  transform: translateY(-150%);
}

@media screen and (min-width: 600px) {
  .menu {
    left: 60%;
  }
}

@media screen and (min-width: 850px) {
  .menu {
    position: static;
    transform: translateX(0%);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px;
    padding: 0px;
    order: 2;
    flex: 4;
    background-color: transparent;
    border: none;
  }

  .menu * {
    flex: 1;
  }

  .menu a {
    display: block;
    margin: 0px;
    padding: 0px;
    width: 10px;
    text-decoration: none;
    font-size: 0.8rem;
  }

  .menu li {
    margin: 0px;
    padding: 0px;
    width: 100%;
    text-decoration: none;
    display: block;
    margin: auto;
    text-align: center;
  }

  .buttons {
    display: inline;
    order: 3;
    flex: 0.25;
    text-align: right;
    margin-right: 2rem;
  }

  #burgerBtn {
    display: none;
  }
}
