footer * {
  margin: 0px;
  padding: 0px;
  color: black;
}

footer {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
}

.copyright {
  font-size: 0.8rem;
  align-self: flex-end;
  margin: 1rem auto;
  opacity: 1;
  padding: 20px;
}
