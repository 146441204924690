html {
  font-size: 100%;
  margin: 0px;
  padding: 0px;
  font-family: "Inter", sans-serif;
  position: relative;
  background-color: #f0e7db;
}

html * {
  position: relative;
}

body {
  margin: 0px;
  position: relative;
  min-height: 100vh;
}

.main {
  min-height: 95vh;
}

.light * {
  color: black;
  background-color: #f0e7db;
  border-color: black;
  position: relative;
  transition: 0.2s;
}

.dark * {
  color: white;
  background-color: black;
  border-color: white;
  position: relative;
  transition: 0.2s;
}

@media screen and (min-width: 850px) {
  html {
    position: relative;
    width: 90%;
    margin: auto;
    font-size: 150%;
  }
  body {
    width: 90%;
    margin: auto;
  }
  #header {
    width: 81%;
  }
}
